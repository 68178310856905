import React from "react";

import Layout from "../layouts/ja";
import SEO from "../components/seo";

import { AccountBlocked } from "../components/TWComponents/AccountBlocked";

const AccountBlockedPage = (props) => {
  return (
    <Layout location={props.location} showBookNowButton={false}>
      <SEO
        title="Account Deactivated"
        keyword="Account Deactivated"
        decription="Account Deactivated"
      />
      <AccountBlocked
        title="アカウントが無効化されました"
        subDesciption="アカウントが無効になりました。考えられる理由は以下の通りです：24か月以上のご利用がなかった場合、無効なメールアドレスを登録された場合、あるいは別のアカウントに統合された場合です。"
        decription='アカウントを再有効化するには、<a href="mailto:info@ayanarewards.com?subject=AYANA%20Rewards%20Account%20Reactivation">info@ayanarewards.com<a>こちらまで</a>お問い合わせください。'
        button="Account Deactivated"
      />
    </Layout>
  );
};

export default AccountBlockedPage;
